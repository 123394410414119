import itemTypes from './itemTypes'
import userRole from './userRole'
import theme from './theme'

export default [
  {
    label: 'New Order',
    value: itemTypes.NEW_ORDER,
    name: 'new-order',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: ['in-progress'],
      [userRole.MANAGER]: ['in-progress'],
      [userRole.CREATIVE_MANAGER]: ['in-progress'],
      [userRole.PRODUCTION_MANAGER]: ['in-progress'],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'In Progress',
    value: itemTypes.IN_PROGRESS,
    name: 'in-progress',
    allowApprover: false,
    color: theme.DARK,
    access: {
      [userRole.OPERATOR]: ['in-review'],
      [userRole.MANAGER]: ['in-review'],
      [userRole.CREATIVE_MANAGER]: ['in-review'],
      [userRole.PRODUCTION_MANAGER]: ['in-review'],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'In Review',
    value: itemTypes.IN_REVIEW,
    name: 'in-review',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: ['in-progress', 'approved'],
      [userRole.CREATIVE_MANAGER]: ['in-progress', 'approved'],
      [userRole.PRODUCTION_MANAGER]: ['in-progress', 'approved'],
      [userRole.OWNER]: ['in-progress', 'approved'],
      [userRole.STAFF]: ['in-progress', 'approved'],
      [userRole.WHITELABEL_MEMBER]: ['in-progress', 'approved'],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'On Hold',
    value: itemTypes.ON_HOLD,
    name: 'on-hold',
    color: theme.ORANGE,
    allowApprover: false,
    access: {},
    selectedBackgroundColor: theme.ORANGE,
    selectedColor: '#fff',
  },
  {
    label: 'Approved',
    value: itemTypes.APPROVED,
    name: 'approved',
    color: theme.DARKBLUE,
    allowApprover: true,
    access: {
      [userRole.OPERATOR]: ['completed'],
      [userRole.MANAGER]: ['in-review', 'completed'],
      [userRole.CREATIVE_MANAGER]: ['in-review', 'completed'],
      [userRole.PRODUCTION_MANAGER]: ['in-review', 'completed'],
      [userRole.OWNER]: ['in-review', 'completed'],
      [userRole.STAFF]: ['in-review', 'completed'],
      [userRole.WHITELABEL_MEMBER]: ['in-review', 'completed'],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Completed',
    value: itemTypes.COMPLETED,
    name: 'completed',
    color: theme.DARKBLUE,
    allowApprover: true,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: ['approved'],
      [userRole.CREATIVE_MANAGER]: ['approved'],
      [userRole.PRODUCTION_MANAGER]: ['approved'],
      [userRole.OWNER]: ['approved'],
      [userRole.STAFF]: ['approved'],
      [userRole.WHITELABEL_MEMBER]: ['approved'],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Cancelled',
    value: itemTypes.CANCELLED,
    name: 'cancelled',
    color: theme.RED,
    allowApprover: false,
    access: {},
    selectedBackgroundColor: theme.RED,
    selectedColor: '#fff',
  },
]
